import {oauth} from "./oauth";
import {bell} from "./bell";
import {classroom} from "./classroom";
import {reserve} from "./reserve";
import {schoolClass} from "./school-class";
import {teacher} from "./teacher";
import {schedule} from "./schedule";

const api = {
  oauth, // OAuth2
  bell, // школьные звонки
  classroom, // аудитории
  reserve, // бронирования аудиторий
  schoolClass, // школьные классы
  teacher, // преподаватели
  schedule, // расписание
};

export {api};