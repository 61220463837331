<template>
  <RouterLink
      :to="`${URL_CLASSES}/${schoolClass.name}`"
      :class="`class-item class-item_${parallel}`">
    {{ schoolClassNameWithoutZero(schoolClass.name) }} класс
  </RouterLink>
</template>

<script setup>
import {defineProps} from "vue";
import {URL_CLASSES} from "@/data/constants";

defineProps({
  parallel: {
    type: Number,
    required: true,
  },
  schoolClass: {
    type: Object,
    required: true,
  },
});

const schoolClassNameWithoutZero = (name) => {
  return name.replace(/^(0*)/g, '');
}
</script>

<style lang="scss" scoped>
.class-item {
  background-color: #ECF2EF;
  border-radius: 5px;
  color: #000000;
  display: block;
  line-height: 1.2;
  text-align: center;
  padding: 30px 15px;
  font-size: 0.8rem;
  text-decoration: none;
  text-transform: uppercase;

  &.class-item_10 {
    background-color: #E9F3FA;
  }

  &.class-item_11 {
    background-color: #F4EEEB;
  }
}
</style>