<template>
  <div class="inner-banner">
    <h1 class="inner-banner__header" v-html="prepareHeader(text)" />
    <img src="../assets/icon-cube.png" alt="" class="inner-banner__image inner-banner__image_cube">
    <img src="../assets/icon-cylinder.png" alt="" class="inner-banner__image inner-banner__image_cylinder">
    <template v-if="description">
      <div v-html="description" class="description"></div>
    </template>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  text: {
    type: String,
    required: true,
  },
  description: {
    type: String
  }
});

const prepareHeader = (text) => {
  return text.replaceAll('_','<span class="underline">-</span>');
}
</script>

<style lang="scss" scoped>
.inner-banner {
  background: url("../assets/background.png") center/cover no-repeat scroll padding-box;
  border-radius: 16px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
  position: relative;
  height: 320px;
  max-height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    border-radius: 6px;
    max-height: 157px;
  }
  .inner-banner__header {
    color: white;
    font: normal 5rem "Chalk cyrillic freehand", sans-serif;
    text-align: center;
    text-transform: uppercase;

    :deep(.underline) {
      position: relative;
      top: 16px;
    }

    @media (max-width: 767px) {
      font-size: 2rem;

      :deep(.underline) {
        top: 6px;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 2.3rem;

      :deep(.underline) {
        top: 8px;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 4.5rem;

      :deep(.underline) {
        top: 14px;
      }
    }
  }
  .description{
    position: absolute;
    bottom: 10px;
    padding-bottom: 20px;
    font-size: .9rem;
    color: #fff;
    font-family: "Acrom", sans-serif;
    text-align: center;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: .7rem;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: .6rem;
    }
    @media (max-width: 576px) {
      font-size: .5rem;
    }
  }
  .inner-banner__image {
    display: block;
    position: absolute;
  }

  .inner-banner__image_cube {
    right: 20px;
    top: 20px;
    @media (max-width: 767px) {
      width: 55px;
      right: 5px;
      top: 5px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 75px;
      right: 5px;
      top: 5px;
    }
  }

  .inner-banner__image_cylinder {
    bottom: 20px;
    left: 20px;
    @media (max-width: 767px) {
      width: 55px;
      bottom: 5px;
      left: 5px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 105px;
      bottom: 5px;
      left: 5px;
    }
  }
}
</style>