<template>
  <div class="classes-blocks">
    <div v-for="parallel in parallels" :key="parallel" class="classes-blocks__item">
      <ClassesBlock :parallel="parallel" :schoolClasses="getClassesByParallel(parallel)"/>
      <PreloaderSpinner v-if="loading" />
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {api} from "@/services/backend";
import ClassesBlock from "@/features/classes-blocks/components/ClassesBlock.vue";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";

const parallels = ref([9, 10, 11]);
const schoolClasses = ref([]);
const loading = ref(false);

const getClasses = async () => {
  loading.value = true;
  try {
    const response = await api.schoolClass.all();
    schoolClasses.value = response.groups;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

const getClassesByParallel = (parallel) => {
  return schoolClasses.value.filter((schoolClass) => parallel === schoolClass.parallel);
};

onMounted(() => {
  getClasses();
});
</script>

<style lang="scss" scoped>
.classes-blocks {
  display: flex;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .classes-blocks__item {
    flex: 1 1 auto;
  }
}
</style>