<template>
  <div class="schedule-cell">
    <div class="schedule-cell__header">
      <div class="schedule-cell__lesson-name">{{ lesson.name }}</div>
      <div class="schedule-cell__lesson-type">
        <LessonTypeIcon :type="lesson.type"/>
      </div>
    </div>
    <div class="schedule-cell__content">
      <div v-if="classroom" class="schedule-cell__classroom-group">
        <div class="schedule-cell__classroom">
          <LinkItem :to="`${URL_CLASSROOMS}/${classroom.name}`" :text="classroom.name"/>
        </div>
        <div v-if="group && group.subgroup" class="schedule-cell__group">
          гр. {{ group.subgroup }}
        </div>
      </div>
      <div v-if="teacher" class="schedule-cell__teacher">
        <LinkItem :to="`${URL_TEACHERS}/${teacher.name}`" :text="teacher.name"/>
      </div>
      <div v-if="schoolClasses && 0 < schoolClasses.length" class="schedule-cell__school-classes">
        <template v-for="(schoolClass, index) in schoolClasses" :key="schoolClass.id">
          <LinkItem
              :to="`${URL_CLASSES}/${schoolClass.name}`"
              :text="`${schoolClassNameWithoutZero(schoolClass.name)} кл.`"/>
          <span v-if="index + 1 < schoolClasses.length" class="schedule-cell__school-classes-separator">, </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import LessonTypeIcon from "@/features/schedule/components/LessonTypeIcon.vue";
import {URL_CLASSES, URL_CLASSROOMS, URL_TEACHERS} from "@/data/constants";
import LinkItem from "@/components/LinkItem.vue";

defineProps({
  lesson: {
    type: Object,
    required: true,
  },
  classroom: Object,
  teacher: Object,
  schoolClasses: {
    type: Array,
    default: () => [],
  },
  group: Object,
});

const schoolClassNameWithoutZero = (name) => {
  return name.replace(/^(0*)/g, '');
}
</script>

<style lang="scss" scoped>
.schedule-cell {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px 20px;

  .schedule-cell__header {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .schedule-cell__lesson-name {
    flex: 1 1 auto;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .schedule-cell__content {
    color: #383D4F;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    gap: 10px;
  }

  .schedule-cell__classroom-group {
    display: flex;
    gap: 30px;
  }

  .schedule-cell__classroom,
  .schedule-cell__group {
    flex: 1 1 auto;
  }
}
</style>