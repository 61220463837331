<template>
  <div class="organisation-name">
    Специализированный учебно-научный центр НГУ
  </div>
</template>

<style lang="scss" scoped>
.organisation-name {
  font-weight: bold;
  text-transform: uppercase;
}
</style>