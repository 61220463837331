<template>
  <InnerLayout>
    <template #banner>
      <BannerInner text="Расписание аудиторий"/>
    </template>
    <template #default>
      <FilterItems :items="classroomsFilter" :to="`${URL_CLASSROOMS}/?filter=`"/>
      <PreloaderSpinner v-if="loading" />
      <ClassroomsList :items="classrooms" />
    </template>
  </InnerLayout>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {api} from '@/services/backend';
import BannerInner from "@/features/banners/components/BannerInner.vue";
import InnerLayout from "@/layouts/InnerLayout.vue";
import FilterItems from "@/features/filter-blocks/components/FilterItems.vue";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";
import {URL_CLASSROOMS} from "@/data/constants";
import ClassroomsList from "@/features/classrooms/components/ClassroomsList.vue";

const route = useRoute();
const router = useRouter();
const classroomsFilter = ref([]);
const classrooms = ref([]);
const loading = ref(false);

const getClassroomsFilter = async () => {
  loading.value = true;
  try {
    const response = await api.classroom.filters();
    classroomsFilter.value = response.filters;
    loading.value = false;
    // Если фильтр есть, но не выбрано ничего, то применяем первый
    if (route.query.filter === undefined && classroomsFilter.value[0] !== undefined) {
      await router.push({
        path: URL_CLASSROOMS,
        params: {},
        query: {
          ...route.query,
          filter: classroomsFilter.value[0].text
        }
      });
    }
  } catch (error) {
    console.error('error', error);
  }
}

const getClassrooms = async () => {
  loading.value = true;
  try {
    if (route.query.filter === undefined) return;
    const response = await api.classroom.all(
        new URLSearchParams({
          filter: route.query.filter,
        })
    );
    classrooms.value = response.classrooms;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

onMounted(() => {
  getClassroomsFilter();
  getClassrooms();
});

watch(() => route.query, () => {
  getClassrooms();
});
</script>

<style lang="scss" scoped>

</style>