<template>
  <InnerLayout>
    <template #banner>
      <BannerInner :text="`Аудитория ${classroomName}`" :description="description"/>
    </template>
    <template #default>
      <ScheduleComponent :type="SCHEDULE_TYPE_CLASSROOM" :classroomName="classroomName" :canReserve="canReserve"/>
      <div id="captcha-container"></div>
    </template>
  </InnerLayout>
</template>

<script setup>
import {useRoute} from "vue-router";
import {SCHEDULE_TYPE_CLASSROOM} from "@/data/constants";
import InnerLayout from "@/layouts/InnerLayout.vue";
import BannerInner from "@/features/banners/components/BannerInner.vue";
import ScheduleComponent from "@/features/schedule/components/ScheduleComponent.vue";
import {ref} from "vue";

const canReserve = ref(true);
const route = useRoute();
const {classroomName} = route.params;
const description = "Чтобы забронировать аудиторию, наведите на нужную дату <br> и время и нажмите по кнопке Забронировать";

</script>

<style lang="scss" scoped>

</style>