<template>
  <main class="main-layout">
    <div class="main-layout__banner">
      <slot name="banner"/>
    </div>
    <div class="main-layout__content">
      <slot/>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.main-layout {
  .main-layout__banner {
    margin-bottom: 120px;
    @media (max-width: 767px) {
      margin-bottom: 100px;
    }
  }
}
</style>
