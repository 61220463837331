<template>
  <ul class="filter-items tabs">
    <li v-for="studyDay in studyDaysShort" :key="studyDay" class="tab">
      <ButtonLinkFilter
          :to="`?day=${studyDay.id}`"
          :text="studyDay.name"
          :active="studyDay.id === getSelectDay()"
      />
    </li>
  </ul>
</template>

<script setup>
import {useRoute} from "vue-router";
import {studyDaysShort} from "@/data/days";
import ButtonLinkFilter from "@/components/ButtonLinkFilter.vue";

const route = useRoute();

const getSelectDay = () => {
  let day = parseInt(route.query.day);
  if (!day) {
    let d = new Date();
    return [1, 1, 2, 3, 4, 5, 6][d.getDay()];
  }
  return day;
}
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 0;
}
</style>