import {LESSON_TYPE_LAB, LESSON_TYPE_LECTURE, LESSON_TYPE_PRACTICE} from "@/features/schedule/data/constants";

const LessonType = {
  getLabel(typeId) {
    if (LESSON_TYPE_LECTURE === typeId) {
      return 'лек';
    } else if (LESSON_TYPE_PRACTICE === typeId) {
      return 'пр';
    } else if (LESSON_TYPE_LAB === typeId) {
      return 'лаб';
    }

    return '';
  },
  getCssClass(typeId) {
    if (LESSON_TYPE_LECTURE === typeId) {
      return 'lesson-type_lecture';
    } else if (LESSON_TYPE_PRACTICE === typeId) {
      return 'lesson-type_practice';
    } else if (LESSON_TYPE_LAB === typeId) {
      return 'lesson-type_lab';
    }

    return '';
  },
};

export {LessonType};