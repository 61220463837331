import axios from "axios";
import {getBaseUrl} from "../env/base-url";
import {DEFAULT_TIMEOUT} from "../data/constants";
import {toAxios} from "../adapters/axios";
import {api} from "../api";
import {createTokenIsRefreshing, removeTokenIsRefreshing} from "@/storage/local/token";
import {token} from "../token/token";
import Queue from "../request/Queue";
import {
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError
} from "../exceptions/http-errors";

class HttpClient {
  constructor() {
    this._initClient();
    this._queue = new Queue();
  }

  /**
   * @param request
   * @returns {Promise<unknown>}
   */
  async call(request) {
    if (!request.isAuthenticated) {
      return this.run(request);
    }

    if (token.isRefreshing()) {
      return this._queue.push(request);
    }

    if (!token.isExpired()) {
      return this.run(request);
    }

    if (!token.canRefresh()) {
      throw new UnauthorizedError();
    }

    await this._refreshToken();

    return this.run(request);
  }

  async run(request) {
    try {
      return await this._client.request(toAxios(request));
    } catch (error) {
      if (400 === error.status) {
        throw new BadRequestError(error.data.message, error.data);
      } else if (401 === error.status) {
        throw new UnauthorizedError(error.data.message, error.data);
      } else if (403 === error.status) {
        throw new ForbiddenError(error.data.message, error.data);
      } else if (404 === error.status) {
        throw new NotFoundError(error.data.message, error.data);
      }

      throw new InternalServerError(error.data.message, error.data);
    }
  }

  _initClient() {
    this._client = axios.create({
      baseURL: getBaseUrl(),
      timeout: DEFAULT_TIMEOUT,
    });

    this._client.interceptors.response.use(
      response => response.data.payload,
      error => Promise.reject(error.response)
    );
  }

  async _refreshToken() {
    try {
      createTokenIsRefreshing();
      const response = await api.oauth.refreshToken.createToken({refresh_token: token.refresh});
      token.saveToken(response);
      await this._handleQueue();
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      this._queue.reset();
      removeTokenIsRefreshing();
    }
  }

  _handleQueue() {
    return Promise.allSettled(this._queue.buildItems(this));
  }
}

let instance = null;

const httpClient = () => {
  if (null === instance) {
    instance = new HttpClient();
  }

  return instance;
};

export {httpClient};