<template>
  <div class="copyright-item">
    © 2024 Новосибирский государственный университет
  </div>
</template>

<style lang="scss" scoped>
.copyright-item {
  font-weight: 300;
}
.mobile{
  .copyright-item{
    margin-top: 50px;
    font-family: "Acrom", sans-serif;
    font-size: 0.65rem;
  }
}
</style>