<template>
  <a v-if="isGlobal(to)" :href="to" class="button-link" target="_blank">{{ text }}</a>
  <RouterLink v-else :to="to" class="button-link">{{ text }}</RouterLink>
</template>

<script setup>
import {defineProps} from "vue";

defineProps({
  text: {
    type: String,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
});

const isGlobal = (link) => {
  return link.includes('://');
};
</script>

<style lang="scss" scoped>
.button-link {
  background-color: #345D32;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:hover{
    background-color: #2F472E;
  }
  &:focus{
    background-color: #2F472E;
  }
}
</style>