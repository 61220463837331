<template>
  <div class="schedule-cards">
    <div v-for="item in items" :key="item.to" class="schedule-cards__item">
      <ScheduleCard :text="item.text" :to="item.to" :image="item.image"/>
    </div>
  </div>
</template>

<script setup>
import ScheduleCard from "@/features/schedule-cards/components/ScheduleCard.vue";
import {URL_CLASSES, URL_CLASSROOMS, URL_TEACHERS} from "@/data/constants";
import image1 from "../assets/1.png";
import image2 from "../assets/2.png";
import image3 from "../assets/3.png";

const items = [
  {
    image: image1,
    to: URL_CLASSES,
    text: 'Расписание классов',
  },
  {
    image: image2,
    to: URL_TEACHERS,
    text: 'Расписание преподавателей',
  },
  {
    image: image3,
    to: URL_CLASSROOMS,
    text: 'Расписание аудиторий',
  }
];
</script>

<style lang="scss" scoped>
.schedule-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .schedule-cards__item {
    flex: 1 1 auto;
  }
}
</style>