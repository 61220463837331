<template>
  <div class="filter-items">
    <div class="filter-items__inner">
      <div v-for="item in items" :key="item">
        <ButtonLinkFilter :to="to + item.text" :text="item.text" :active="item.text === route.query.filter" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import ButtonLinkFilter from "@/components/ButtonLinkFilter.vue";
import {useRoute} from "vue-router";

const route = useRoute();

defineProps({
  items: {
    type: Object,
    required: true,
  },
  to: {
    type: String,
    required: true,
  }
});

</script>

<style lang="scss" scoped>
.filter-items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  overflow: hidden;
  height: auto;
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    height: 56px;
  }

  .filter-items__inner {
    display: flex;
    gap: 10px;
    flex-wrap:wrap;
    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      padding-bottom: 17px; /* Increase/decrease this value for cross-browser compatibility */
      box-sizing: content-box; /* So the width will be 100% + 17px */
      flex-wrap: nowrap;
    }
  }
}
</style>