<template>
  <ModalDialog title="Расписание звонков" id="bellsModal" class="bells-modal">
    <PreloaderSpinner v-if="loading"/>
    <div class="bells">
      <template v-for="(bell, i) in bells" :key="bell">
        <div class="bells__group" v-if="i % 2 === 0">
          <span>{{ getNumString(i/2+1) }} пара</span>
        </div>
        <div class="bells__item" :class="{'bells__item-first': i % 2 === 0, 'bells__item-second': i % 2 !== 0}">
          <span>{{ bell.begin }}-{{ bell.end }}</span>
        </div>
      </template>
    </div>
  </ModalDialog>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {api} from "@/services/backend";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";
import ModalDialog from "@/features/modal/components/ModalDialog.vue";

const bells = ref([]);
const loading = ref(false);

const NUMS = [
    '',
    'Первая',
    'Вторая',
    'Третья',
    'Четвёртая',
    'Пятая',
    'Шестая',
    'Седьмая',
    'Восьмая',
    'Девятая',
    'Десятая',
    'Одиннадцатая',
    'Двенадцатая',
    'Тринадцатая',
];

const getBells = async () => {
  loading.value = true;
  try {
    const response = await api.bell.all();
    bells.value = response.bells;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

const getNumString = (num) => {
  return NUMS[num];
}

onMounted(() => {
  getBells();
});
</script>

<style lang="scss" scoped>
.bells {
  display: grid;
  grid-template-columns: 160px auto;
  justify-content: start;

  .bells__group {
    height: 50px;
    padding: 0 36px 0 0;
    margin-bottom: 40px;
    grid-row: span 2;
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    font-family: AcromBold, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    &:after {
      display: block;
      content: '{';
      color: #E8EDF3;

      font-family: Acrom, sans-serif;
      font-weight: 400;
      font-size: 56px;
      line-height: 45px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .bells__item {
    height: 25px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    font-family: Acrom, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    &.bells__item-second {
      margin-bottom: 40px;
    }
  }
}
.bells-modal {
  .modal-dialog {
    margin-right: 50px;
    margin-left: auto;

    @media (min-width: 992px) and (max-width: 1060px) {
      margin-right: 50px;
    }
    @media (min-width: 1061px) and (max-width: 1220px) {
      margin-right: 50px;
    }
    @media (min-width: 1201px) and (max-width: 1290px) {
      margin-right: 50px;
    }
    @media (max-width:767px){
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>