<template>
  <div class="spinner"></div>
</template>

<script setup></script>

<style lang="scss" scoped>
.spinner {
  width: 30px;
  height: 30px;
  display: grid;
  animation: spinner-rotate 0.3s infinite;
  margin: auto auto -30px;

  &::before,
  &::after {
    content: "";
    grid-area: 1/1;
    border: 5px solid;
    border-radius: 50%;
    border-color: #345d32 #345d32 #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner-plncf9 1s infinite linear;
  }

  &::after {
    border-color: #0000 #0000 #e8edf3 #e8edf3;
    animation-direction: reverse;
  }
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>