<template>
  <div class="header__menu collapse mobile" id="navbarCollapse">
    <div class="backdrop"></div>
    <div class="header__menu__container">
      <div class="header__menu__container__header">
        <LogoItem/>
        <ButtonClose/>
      </div>
      <MenuMain/>
    </div>
  </div>
</template>

<script setup>
import {watch} from 'vue';
import {useRoute} from "vue-router";
import LogoItem from "@/features/logo/components/LogoItem.vue";
import ButtonClose from "@/features/button/components/ButtonClose.vue";
import MenuMain from "@/features/menu-main/components/MenuMain.vue";

const route = useRoute();

watch(() => route.query, () => {
  const navbar = document.getElementById('navbarCollapse');
  const closeBtn = document.getElementById('closeMenuButton');
  if(navbar.classList.contains("show")) {
    closeBtn.click();
  }
});
</script>

<style lang="scss" scoped>
.header__menu{
  &.mobile{
    position: absolute;
    width: 100%;
    top: 0px;
    @media (min-width: 992px) {
      display: none;
    }
    .header__menu__container{
      position: absolute;
      z-index: 1050;
      background: white;
      width: 100%;
      display: none;
      padding: 10px 15px 30px;
      border-radius: 15px;
      @media (max-width: 991px){
        flex-direction: column;
      }
      .header__menu__container__header{
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        margin-bottom: 20px;
        @media (max-width: 767px) {
          height: 40px;
        }
      }
    }
  }
  &.show{
    transition: all .1s linear;
    .backdrop{
      opacity: .5;
    }
    .header__menu__container{
      display: flex;
    }
  }
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
}
</style>