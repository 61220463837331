<template>
  <InnerLayout>
    <template #banner>
      <BannerInner :text="`${schoolClassNameWithoutZero(schoolClassName)} класс`"/>
    </template>
    <template #default>
      <ScheduleComponent :type="SCHEDULE_TYPE_CLASS" :schoolClassName="schoolClassName"/>
    </template>
  </InnerLayout>
</template>

<script setup>
import {useRoute} from "vue-router";
import InnerLayout from "@/layouts/InnerLayout.vue";
import BannerInner from "@/features/banners/components/BannerInner.vue";
import ScheduleComponent from "@/features/schedule/components/ScheduleComponent.vue";
import {SCHEDULE_TYPE_CLASS} from "@/data/constants";

const route = useRoute();
const {schoolClassName} = route.params;

const schoolClassNameWithoutZero = (name) => {
  return name.replace(/^(0*)/g, '');
}
</script>