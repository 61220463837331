import MainPage from "@/pages/MainPage.vue";
import {URL_CLASSES, URL_CLASSROOMS, URL_TEACHERS} from "@/data/constants";
import ClassesPage from "@/pages/ClassesPage.vue";
import TeachersPage from "@/pages/TeachersPage.vue";
import ClassroomsPage from "@/pages/ClassroomsPage.vue";
import {createRouter, createWebHistory} from "vue-router";
import ClassPage from "@/pages/ClassPage.vue";
import TeacherPage from "@/pages/TeacherPage.vue";
import ClassroomPage from "@/pages/ClassroomPage.vue";

const routes = [
  {path: '/', component: MainPage},
  {path: URL_CLASSES, component: ClassesPage},
  {path: `${URL_CLASSES}/:schoolClassName`, component: ClassPage},
  {path: URL_TEACHERS, component: TeachersPage},
  {path: `${URL_TEACHERS}/:teacherName`, component: TeacherPage},
  {path: URL_CLASSROOMS, component: ClassroomsPage},
  {path: `${URL_CLASSROOMS}/:classroomName`, component: ClassroomPage},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export {router};