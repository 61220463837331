<template>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
</template>

<script setup>


</script>

<style lang="scss" scoped>
.navbar-toggler{
  border: none;
  background: transparent;
  &:hover, &:focus{
    box-shadow: none;
  }
  .navbar-toggler-icon{
    background: url("../assets/lines.svg") center/cover no-repeat;
    width: 2.5em;
    height: 2.5em;
  }
}
</style>