const studyDays = [
    {'id': 1, 'name': 'Понедельник'},
    {'id': 2, 'name': 'Вторник'},
    {'id': 3, 'name': 'Среда'},
    {'id': 4, 'name': 'Четверг'},
    {'id': 5, 'name': 'Пятница'},
    {'id': 6, 'name': 'Суббота'},
];

const studyDaysShort = [
    {'id': 1, 'name': 'ПН'},
    {'id': 2, 'name': 'ВT'},
    {'id': 3, 'name': 'СР'},
    {'id': 4, 'name': 'ЧТ'},
    {'id': 5, 'name': 'ПТ'},
    {'id': 6, 'name': 'СБ'},
];

export {studyDays, studyDaysShort};