<template>
  <form @submit.prevent="submitForm" novalidate class="reserveForm">
    <div class="mb-3">
      <input
          type="text"
          name="name"
          autocomplete="off"
          v-model="state.name"
          placeholder="Укажите свое ФИО"
          :class="{'invalid':v$.name.$error}"
          class="form-control"
          @blur="v$.name.$touch"
      />
      <div v-if="v$.name.$error" class="errorMessages">{{ v$.name.$errors[0].$message }}</div>
    </div>
    <div class="mb-3">
      <input
          type="email"
          name="mail"
          autocomplete="off"
          v-model="state.email"
          placeholder="Укажите свой email"
          :class="{'invalid':v$.email.$error}"
          class="form-control"
          @blur="v$.email.$touch"
      />
      <div v-if="v$.email.$error" class="errorMessages">{{ v$.email.$errors[0].$message }}</div>
    </div>
    <div class="mb-3">
      <input
          type="text"
          name="phone"
          autocomplete="off"
          v-model="state.phone"
          placeholder="Укажите свой контактный номер телефона"
          :class="{'invalid':v$.phone.$error}"
          class="form-control"
          @blur="v$.phone.$touch"
      />
      <div v-if="v$.phone.$error" class="errorMessages">{{ v$.phone.$errors[0].$message }}</div>
    </div>
    <div class="mb-3">
    <textarea
        rows="5"
        name="message"
        autocomplete="off"
        v-model="state.message"
        placeholder="Укажите, для какого мероприятия бронируете аудиторию и на какое время"
        :class="{'invalid':v$.message.$error}"
        class="form-control"
        @blur="v$.message.$touch"
    ></textarea>
      <div v-if="v$.message.$error" class="errorMessages">{{ v$.message.$errors[0].$message }}</div>
    </div>
    <InputItem type="hidden" name="bell" :value="bell"/>
    <InputItem type="hidden" name="studyDay" :value="studyDay"/>
    <InputItem type="hidden" name="classroomName" :value="classroomName"/>
    <SmartCaptcha
        :sitekey="VITE_YANDEX_SMART_CAPTCHA_KEY"
        :on-success="onSuccessAction"
        :invisible=false
        hl="ru"
    />
    <FormButtonSubmit text="Забронировать" />
  </form>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import {defineProps} from "vue";
import FormButtonSubmit from "@/components/FormButtonSubmit.vue";
import {FORM_INVALID_EMAIL, FORM_REQUIRED_FIELD} from "@/data/helpers/messages";
import InputItem from "@/components/InputItem.vue";
import {api} from "@/services/backend";
import {Modal} from "bootstrap"
import { SmartCaptcha } from 'vue3-smart-captcha';
import {VITE_YANDEX_SMART_CAPTCHA_KEY} from "@/data/constants";

const props = defineProps({
  bell: {
    type: String,
    required: true,
  },
  studyDay: {
    type: String,
    required: true,
  },
  classroomName: {
    type: String,
    required: true,
  },
  modal:{
    type: Object
  }
});


const state = reactive({
  email: '',
  name: '',
  phone: '',
  message: '',
  bell: '',
  day: '',
  classroom: props.classroomName,
  token: ''
})

const modalSuccess = ref();
const form = ref();
const canSend = ref(false);
const rules = computed(() => (
    {
      name: {
        required: helpers.withMessage(FORM_REQUIRED_FIELD, required),
      },
      email: {
        required: helpers.withMessage(FORM_REQUIRED_FIELD, required),
        email: helpers.withMessage(FORM_INVALID_EMAIL, email)
      },
      phone: {
        required: helpers.withMessage(FORM_REQUIRED_FIELD, required),
      },
      message: {
        required: helpers.withMessage(FORM_REQUIRED_FIELD, required),
      },
    }
));


const v$ = useVuelidate(rules, state);

const onSuccessAction = (token) => {
  state.token = token;
  canSend.value = true;
}

const submitForm = () => {
  form.value = this;
  const result = v$.value.$validate();
  result.then(async (res) => {
    if (res && canSend.value) {
      state.bell = props.bell;
      state.day = props.studyDay;
      const response = await api.reserve.create({'ReserveMessage':state});
      if(response.reserve){
        props.modal.hide();
        resetForm();
        modalSuccess.value = new Modal(document.getElementById('reserveSuccessModal'), {});
        modalSuccess.value.show();
      }
    }
  }).catch((err) => {
    console.log(err);
  })
};

const resetForm = () => {
  state.name = '';
  state.email = '';
  state.phone = '';
  state.message = '';
  state.token = '';
  canSend.value = false;
  window.smartCaptcha.reset();
};

onMounted(() => {
  document.addEventListener('hidden.bs.modal', () => {
    state.token = '';
    canSend.value = false;
    window.smartCaptcha.reset();
  });
})

</script>

<style lang="scss" scoped>
.reserveForm{
  width: 600px;
  @media (max-width:991px) {
    width: 100%;
  }
  .form-button-submit{
    margin-top: 30px;
  }
}
input{
  &.form-control{
    height: 50px;
    background: #E8EDF3;
  }
}
input,
textarea{
  &.form-control {
    background: #E8EDF3;
    font-size: .88rem;
    color: #000;
    font-family: "Acrom Medium", sans-serif;
    &:focus{
      box-shadow: none;
      border-color: rgb(222, 226, 230);
    }
    &.invalid{
      border-color: #F65160;
    }
  }
}
input::placeholder,
textarea::placeholder{
  font-size: .88rem;
  color: #383D4F;
  font-family: "Acrom Medium", sans-serif;
}
.errorMessages{
  color: #F65160;
  font-family: "Acrom", sans-serif;
  font-size: .8rem;
  margin-top: 5px;
  &:before{
    content: "*";
    color: #F65160;
  }
}
@media (max-width:767px) {
  .smart-captcha {
    min-width: inherit !important;
  }
}


</style>