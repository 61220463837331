<template>
  <RouterLink to="/" class="logo-item">
    <img src="../assets/logo.png" alt="СУНЦ НГУ" class="logo-item__image">
  </RouterLink>
</template>

<style lang="scss" scoped>
.logo-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .logo-item__image {
    width: 177px;
    @media (max-width: 767px){
      width: 118px;
    }
  }
}
</style>