<template>
  <ul class="menu-main">
    <li v-for="item in items" :key="item.to">
      <MenuMainItem :to="item.to" :text="item.text"/>
    </li>
    <li>
      <MenuMainItem to="#" text="Расписание звонков" data-bs-toggle="modal" data-bs-target="#bellsModal"/>
    </li>
  </ul>
</template>

<script setup>
import MenuMainItem from "@/features/menu-main/components/MenuMainItem.vue";
import {URL_CLASSES, URL_CLASSROOMS, URL_TEACHERS} from "@/data/constants";

const items = [
  {
    text: 'Расписание классов',
    to: URL_CLASSES,
  },
  {
    text: 'Расписание преподавателей',
    to: URL_TEACHERS,
  },
  {
    text: 'Расписание аудиторий',
    to: URL_CLASSROOMS,
  }
];


</script>

<style lang="scss" scoped>
.mobile{
  .menu-main {
    li{
      text-align: center;
      background: #E8EDF3;
      margin-bottom: 10px;
      font-family: "Acrom", sans-serif;
      font-weight: bold;
    }
  }
}
.menu-main {
  display: flex;
  list-style: none outside none;
  padding: 0;
  margin: 0;
  @media (max-width:991px){
    flex-direction: column;
  }
}
</style>