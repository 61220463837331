<template>
  <InnerLayout>
    <template #banner>
      <BannerInner text="Расписание преподавателей"/>
    </template>
    <template #default>
      <FilterItems :items="teachersFilter" :to="`${URL_TEACHERS}/?filter=`"/>
      <PreloaderSpinner v-if="loading" />
      <ResultBlock :items="teachers" :to="URL_TEACHERS"/>
    </template>
  </InnerLayout>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {api} from "@/services/backend";
import BannerInner from "@/features/banners/components/BannerInner.vue";
import InnerLayout from "@/layouts/InnerLayout.vue";
import FilterItems from "@/features/filter-blocks/components/FilterItems.vue";
import ResultBlock from "@/features/filter-blocks/components/ResultBlock.vue";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";
import {URL_TEACHERS} from "@/data/constants";

const route = useRoute();
const router = useRouter();
const teachersFilter = ref([]);
const teachers = ref([]);
const loading = ref(false);

const getTeachersFilter = async () => {
  loading.value = true;
  try {
    const response = await api.teacher.filters();
    teachersFilter.value = response.filters;
    loading.value = false;
    // Если фильтр есть, но не выбрано ничего, то применяем первый
    if (route.query.filter === undefined && teachersFilter.value[0] !== undefined) {
      await router.push({
        path: URL_TEACHERS,
        params: {},
        query: {
          ...route.query,
          filter: teachersFilter.value[0].text
        }
      });
    }
  } catch (error) {
    console.error('error', error);
  }
}

const getTeachers = async () => {
  loading.value = true;
  try {
    if (route.query.filter === undefined) return;
    const response = await api.teacher.all(
        new URLSearchParams({
          filter: route.query.filter,
        })
    );
    teachers.value = response.teachers;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

onMounted(() => {
  getTeachersFilter();
  getTeachers();
});

watch(() => route.query, () => {
  getTeachers();
});
</script>

<style lang="scss" scoped>

</style>