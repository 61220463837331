<template>
  <button id="closeMenuButton" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon close-icon"></span>
  </button>
</template>

<script setup>


</script>

<style lang="scss" scoped>
.navbar-toggler{
  border: none;
  background: transparent;
  padding: 0;
  &:hover, &:focus{
    box-shadow: none;
  }
  .navbar-toggler-icon{
    &.close-icon{
      background: url("../assets/close_small.svg") center/cover no-repeat;
      width: 2em;
      height: 2em;
    }
  }
}
</style>