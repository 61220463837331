import {
  getToken,
  getTokenIsRefreshing,
  removeToken,
  removeTokenIsRefreshing,
  setToken
} from "@/storage/local/token";
import {toStorage} from "../adapters/storage";
import {DEFAULT_TIMEOUT} from "../data/constants";

const token = {
  get type() {
    const tokenData = getToken();
    return null !== tokenData ? tokenData.token_type : null;
  },
  get expiresAt() {
    const tokenData = getToken();
    return null !== tokenData ? tokenData.expires_at : null;
  },
  get access() {
    const tokenData = getToken();
    return null !== tokenData ? tokenData.access_token : null;
  },
  get refresh() {
    const tokenData = getToken();
    return null !== tokenData ? tokenData.refresh_token : null;
  },
  save(tokenData) {
    setToken(toStorage(tokenData));
  },
  isExpired() {
    if (null === this.expiresAt) {
      return true;
    }

    const TIME_GAP = 60 * 1000; // 1 minute in milliseconds

    return this.expiresAt - TIME_GAP < Date.now();
  },
  canRefresh() {
    return null !== this.refresh;
  },
  isRefreshing() {
    const time = getTokenIsRefreshing();

    if (null === time) {
      return false;
    }

    return Date.now() < time + DEFAULT_TIMEOUT;
  },
  saveToken(tokenData) {
    setToken(toStorage(tokenData));
  },
  removeTokens() {
    removeToken();
    removeTokenIsRefreshing();
  },
};

export {token};